import React from 'react';
import Layout from '../components/layout';
import ErrorPage404 from '../components/404/404';

export default function Error404({ data }) {
  return (
    <Layout headStyle="cleared">
      <ErrorPage404 />
    </Layout>
  );
}
