import React from 'react';
import SEO from '../seo';
import { Link } from 'gatsby';
import Image404 from './Image404.svg';
import './styles.scss';

const NotFoundPage = ({ data, location }) => {
  return (
    <div className="view-error">
      <SEO title="404: сторінку не знайдено" />
      <div className="view-container view-error__wrapper">
        <h1 className="view-error__title">
          Сторінку не знайдено
        </h1>
        <Image404 />
        <Link to="/" className="button button--primary">Повернутись на головну</Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
